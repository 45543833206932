import "./App.css";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CookeModal } from "./components/CookieModal";
import { WelcomePage } from "./pages/WelcomePage";
import { IntroductionPage } from "./pages/IntroductionPage";
import { ProjectInformation } from "./pages/ProjectInformation";
import { PhaseSelectionPage } from "./pages/PhaseSelectionPage";
import { AcuteSolutionsPage } from "./pages/AcutePhase/AcuteSolutionsPage";
import { TransitionSolutionsPage } from "./pages/TransitionPhase/TransitionSolutionsPage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function App() {
  const [enterDisabled, setEnterDisabled] = useState(true);
  // User data
  const [projectName, setProjectName] = useState("");
  const [location, setLocation] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [date, setDate] = useState(new moment());
  const [phase, setPhase] = useState("");
  const [averageQuantity, setAverageQuantity] = useState('0-2');
  const [availableLand, setAvailableLand] = useState('0-50');
  const [collectedInBarrels, setCollectedInBarrels] = useState(false);
  const [natureOfSludgeFlow, setNatureOfSludgeFlow] = useState("batch");
  const [waterContent, setWaterContent] = useState("low");
  const [averageQuantityOfSludge, setAverageQuantityOfSludge] = useState("0-2");
  const [availableLandTransition, setAvailableLandTransition] = useState("0-50");
  const [powerAvailable, setPowerAvailable] = useState("no");
  const [skilledLabour, setSkilledLabour] = useState("no");

  const navigate = useNavigate();

  useEffect(() => {
    if (enterDisabled) {
      navigate("/");
    }
  }, [enterDisabled, navigate]);

  useEffect(() => {
    if (typeof Cookies.get("project-data") !== "undefined") {
      const data = JSON.parse(Cookies.get("project-data"));
      setProjectName(data.projectName);
      setLocation(data.location);
      setProjectDescription(data.projectDescription);
      setDate(data.date);
      setPhase(data.phase);
      if (data.phase === "acute") {
        setAverageQuantity(data.averageQuantity);
        setAvailableLand(data.availableLand);
        setCollectedInBarrels(data.collectedInBarrels);
      } else if (data.phase === "transition") {
        setNatureOfSludgeFlow(data.natureOfSludgeFlow);
        setWaterContent(data.waterContent);
        setAverageQuantityOfSludge(data.averageQuantityOfSludge);
        setAvailableLandTransition(data.availableLandTransition);
        setPowerAvailable(data.powerAvailable);
        setSkilledLabour(data.skilledLabour);
      }
    }
  }, []);

  return (
    <div className="App">
      <CookeModal />
      <Routes>
        <Route
          path="/"
          element={
            <WelcomePage
              enterDisabled={enterDisabled}
              setEnterDisabled={setEnterDisabled}
            />
          }
        />
        <Route path="introduction" element={<IntroductionPage />} />
        <Route
          path="project-information"
          element={
            <ProjectInformation
              projectName={projectName}
              setProjectName={setProjectName}
              location={location}
              setLocation={setLocation}
              projectDescription={projectDescription}
              setProjectDescription={setProjectDescription}
              date={date}
              setDate={setDate}
            />
          }
        />
        <Route
          path="phase-selection"
          element={<PhaseSelectionPage setPhase={setPhase} phase={phase} />}
        />
        <Route
          path="acute-solutions-page"
          element={
            <AcuteSolutionsPage
              projectName={projectName}
              location={location}
              projectDescription={projectDescription}
              date={date}
              averageQuantity={averageQuantity}
              setAverageQuantity={setAverageQuantity}
              availableLand={availableLand}
              setAvailableLand={setAvailableLand}
              collectedInBarrels={collectedInBarrels}
              setCollectedInBarrels={setCollectedInBarrels}
            />
          }
        />
        <Route
          path="transition-solutions-page"
          element={
            <TransitionSolutionsPage
              projectName={projectName}
              location={location}
              projectDescription={projectDescription}
              date={date}
              natureOfSludgeFlow={natureOfSludgeFlow}
              setNatureOfSludgeFlow={setNatureOfSludgeFlow}
              waterContent={waterContent}
              setWaterContent={setWaterContent}
              averageQuantityOfSludge={averageQuantityOfSludge}
              setAverageQuantityOfSludge={setAverageQuantityOfSludge}
              availableLandTransition={availableLandTransition}
              setAvailableLandTransition={setAvailableLandTransition}
              powerAvailable={powerAvailable}
              setPowerAvailable={setPowerAvailable}
              skilledLabour={skilledLabour}
              setSkilledLabour={setSkilledLabour}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
