import { Box, Typography, TextField, Button } from "@mui/material";
import { TopMenu } from "../components/TopMenu";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

export const ProjectInformation = (props) => {
  const {
    projectName,
    setProjectName,
    location,
    setLocation,
    projectDescription,
    setProjectDescription,
    date,
    setDate,
  } = props;
  const navigate = useNavigate();

  const onSave = () => {
    saveToCookie();
    navigate("/phase-selection");
  };

  const saveToCookie = () => {
    if (Cookies.get("Accept-Cookies") !== "true") return;    
    let data = Cookies.get("project-data") ? JSON.parse(Cookies.get("project-data")) : {};
    
    data.projectName = projectName;
    data.location = location;
    data.projectDescription = projectDescription;
    data.date = date;
    Cookies.set("project-data", JSON.stringify(data));
  };

  return (
    <>
      <TopMenu />
      <Box height={"100%"} width={"80%"} margin={"30px"}>
        <Box>
          <Typography
            variant="h2"
            fontWeight={500}
            align="left"
            marginBottom={1}
          >
            Project Information
          </Typography>
          <Typography variant="h5" align="left" color={"#00000099"}>
            Please provide information for your project
          </Typography>
        </Box>
        <Box
          width={"60%"}
          height={"80%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
        >
          <TextField
            required
            label="Project Name"
            variant="outlined"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            required
            label="Project Location"
            variant="outlined"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            required
            label="Project Description"
            variant="outlined"
            multiline
            rows={5}
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            inputProps={{ maxLength: 200 }}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              format={"DD/MM/YYYY"}
              value={moment(date)}
              onChange={(newValue) => setDate(newValue)}
            />
          </LocalizationProvider>
          <Box display={"flex"} alignItems={"flex-start"}>
            <Button
              sx={{
                backgroundColor: "#233A5C",
                minHeight: "55px",
                minWidth: "210px",
                fontWeight: "bold",
              }}
              variant="contained"
              onClick={onSave}
            >
              Save & Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
