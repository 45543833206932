import { AppBar, Typography, Box } from "@mui/material";
// comment

export const TopMenu = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: "white",
        color: "black",
        height: "7vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
      >
        <Box
          flexGrow={1}
          height={"100%"}
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          paddingLeft={2}
        >
          <Typography>FSTP Decision Tool</Typography>
        </Box>
      </Box>
    </AppBar>
  );
};
