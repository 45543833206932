import { useEffect, useState } from "react";
import db from "../../static/acuteSolutions.json";
import {
  Box,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Alert,
} from "@mui/material";
import { TopMenu } from "../../components/TopMenu";
import Cookies from "js-cookie";
import { AcuteSolution } from "./AcuteSolution";

export const AcuteSolutionsPage = ({
  projectName,
  location,
  projectDescription,
  date,
  averageQuantity,
  setAverageQuantity,
  availableLand,
  setAvailableLand,
  collectedInBarrels,
  setCollectedInBarrels,
}) => {
  const [finalSolution, setFinalSolution] = useState();
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    if (Cookies.get("Accept-Cookies") !== "true") return;

    const data = JSON.parse(Cookies.get("project-data"));
    if (data.solution) {
      setFinalSolution(
        db.find((solution) => solution.technology === data.solution)
      );
    }
  }, []);

  const onQuantityChange = (e) => {
    setAverageQuantity(e.target.value);
  };

  const onAvaliableLandChange = (e) => {
    setAvailableLand(e.target.value);
  };

  const onCollectedInBarrelsChange = (e, newValue) => {
    setCollectedInBarrels(newValue);
  };

  const onSubmit = () => {
    if (collectedInBarrels === false) {
      setAlertVisible(true);
    } else {
      setAlertVisible(false);
      getSolution();
      if (Cookies.get("Accept-Cookies") !== "true") return;

      let data = JSON.parse(Cookies.get("project-data"));
      data.averageQuantity = averageQuantity;
      data.availableLand = availableLand;
      data.collectedInBarrels = collectedInBarrels;
      Cookies.set("project-data", JSON.stringify(data));
    }
  };

  useEffect(() => {
    if (Cookies.get("Accept-Cookies") !== "true" || finalSolution === undefined)
      return;

    let data = JSON.parse(Cookies.get("project-data"));

    data.solution = finalSolution.technology;
    Cookies.set("project-data", JSON.stringify(data));
  }, [finalSolution]);

  const getSolution = () => {
    // Check to see if land is 3000+ no need to filter through db if so
    if (availableLand.split("-")[0] === "3000") {
      if (collectedInBarrels === "yes") {
        setFinalSolution(db[2]);
      } else {
        setFinalSolution(db[0]);
      }
    } else {
      const availableLandMidPoint =
        (parseInt(availableLand.split("-")[0]) +
          parseInt(availableLand.split("-")[1])) /
        2;

      let potentialSolutions = [];
      let eliminatedSoltions = [];

      db.forEach((technology) => {
        const averageQuantityLower = averageQuantity.split("-")[0];
        const averageQuantityUpper = averageQuantity.split("-")[1];

        const techMidPoint =
          (technology.landFootPrintLowerBoundary * averageQuantityLower +
            technology.landFootPrintUpperBoundary * averageQuantityUpper) /
          2;

        if (techMidPoint > availableLandMidPoint) {
          eliminatedSoltions.push(technology);
        } else {
          potentialSolutions.push(technology);
        }
      });

      // Final Solution
      if (potentialSolutions.length === 0) {
        setFinalSolution(db[0]);
        return;
      }

      if (
        potentialSolutions.find(
          (solution) => solution.technology === "Lime in-barrel treatment"
        ) &&
        collectedInBarrels === "yes"
      ) {
        setFinalSolution(
          db.find(
            (solution) => solution.technology === "Lime in-barrel treatment"
          )
        );
        return;
      }

      if (
        potentialSolutions.find(
          (solution) => solution.technology === "Lagoon Lime treatment"
        )
      ) {
        setFinalSolution(db[0]);
      } else {
        setFinalSolution(db[1]);
      }
    }
  };

  return (
    <>
      <TopMenu />
      <Box height={"100%"} display={"flex"} overflow={"hidden"} width={"100%"}>
        <Box
          display={"flex"}
          width={"65%"}
          flexDirection={"column"}
          margin={"40px"}
        >
          <Typography variant="h2" fontWeight={500} align="left">
            Acute Phase
          </Typography>
          <Typography variant="h5" align="left" color={"#00000099"}>
            The ‘Acute Emergency Phase’ in this tool is taken to be the first 6
            months to 1 year of emergency.
          </Typography>
          <Box
            marginTop={"50px"}
            align="left"
            height={"70%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-around"}
          >
            <Typography variant="h5">Input Context Information</Typography>
            <Box
              height={"18%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography>
                What is the average quantity of sludge to be treated (m3/day)?
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="average-quantity-of-sludge-label">
                  Select
                </InputLabel>
                <Select
                  labelId="average-quantity-of-sludge-label"
                  id="average-quantity-of-sludge"
                  value={averageQuantity}
                  label="select"
                  onChange={onQuantityChange}
                >
                  <MenuItem value="0-2">0-2</MenuItem>
                  <MenuItem value="2-4">2-4</MenuItem>
                  <MenuItem value="4-6">4-6</MenuItem>
                  <MenuItem value="6-8">6-8</MenuItem>
                  <MenuItem value="9-10">9-10</MenuItem>
                  <MenuItem value="10-15">10-15</MenuItem>
                  <MenuItem value="15-20">15-20</MenuItem>
                  <MenuItem value="20-25">20-25</MenuItem>
                  <MenuItem value="25-50">25-50</MenuItem>
                  <MenuItem value="50-100">50-100</MenuItem>
                  <MenuItem value="100-200">100-200</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              height={"18%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography>
                What is the available land footprint for a solution (m2)?
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="avaliable-land-label">Select</InputLabel>
                <Select
                  labelId="avaliable-land-label"
                  id="avaliable-land"
                  value={availableLand}
                  label="select"
                  onChange={onAvaliableLandChange}
                >
                  <MenuItem value="0-50">0 - 50</MenuItem>
                  <MenuItem value="51-100">51 - 100</MenuItem>
                  <MenuItem value="101-200">101 - 200</MenuItem>
                  <MenuItem value="201-300">201 - 300</MenuItem>
                  <MenuItem value="301-400">301 - 400</MenuItem>
                  <MenuItem value="401-500">401 - 500</MenuItem>
                  <MenuItem value="501-700">501 - 700</MenuItem>
                  <MenuItem value="701-1000">701 - 1000</MenuItem>
                  <MenuItem value="1001-1250">1001 - 1250</MenuItem>
                  <MenuItem value="1251-1500">1251 - 1500</MenuItem>
                  <MenuItem value="1501-2000">1501 - 2000</MenuItem>
                  <MenuItem value="3000">3000+</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              height={"15%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography paddingRight={"10px"}>
                Is the sludge collected in barrels?
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={collectedInBarrels}
                exclusive
                onChange={onCollectedInBarrelsChange}
                aria-label="Platform"
              >
                <ToggleButton sx={{ width: "150px" }} value="yes">
                  Yes
                </ToggleButton>
                <ToggleButton sx={{ width: "150px" }} value="no">
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#233A5C",
                minHeight: "45px",
                maxWidth: "120px",
                fontWeight: "bold",
              }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Box>
          {alertVisible && (
            <Alert severity="error" onClose={() => setAlertVisible(false)}>
              You have not selected if sludge will be collected in barrels
            </Alert>
          )}
        </Box>
        <Box
          display={"flex"}
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {finalSolution && (
            <AcuteSolution
              projectName={projectName}
              location={location}
              projectDescription={projectDescription}
              date={date}
              finalSolution={finalSolution}
              averageQuantity={averageQuantity}
              availableLand={availableLand}
              collectedInBarrels={collectedInBarrels}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
