import {
  Box,
  Typography,
  Tabs,
  Tab,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useState } from "react";
import { SelectSolution } from "./SelectSolution";
import { TransitionPDFFile } from "../../components/TransitionPDFFile";
import { TechnologyPage } from "./TechnologyPage";

export const TransitionSolution = ({
  projectName,
  projectDescription,
  location,
  date,
  finalSolutions,
  eliminatedSolutions,
  averageQuantityOfSludge,
  natureOfSludgeFlow,
  waterContent,
  availableLandTransition,
  powerAvailable,
  skilledLabour,
}) => {
  const [value, setValue] = useState(0);
  const averageQuantityLower = parseInt(averageQuantityOfSludge.split("-")[0]);
  const averageQuantityUpper = parseInt(averageQuantityOfSludge.split("-")[1]);
  const [selectedSolution, setSelectedSolution] = useState();
  const [eqaulisingTankNeeded, setEqualisingTankNeeded] = useState();
  const [separationNeeded, setSeparationNeeded] = useState();
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const onSelectClick = (solution) => {
    isETneeded(solution);
    isSeparationNeeded(solution);
    setSelectedSolution(solution);
  };

  const isETneeded = (solution) => {
    if (natureOfSludgeFlow === "continuous") {
      setEqualisingTankNeeded(false);
      return;
    }

    if (natureOfSludgeFlow === "batch" && solution.suitableWithBatchLoading) {
      setEqualisingTankNeeded(false);
      return;
    }

    if (
      natureOfSludgeFlow === "batch" &&
      solution.suitableWithBatchLoading === false
    ) {
      setEqualisingTankNeeded(true);
      return;
    }
  };

  const isSeparationNeeded = (solution) => {
    if (solution.suitableForHighWaterContent) {
      setSeparationNeeded(false);
      return;
    }

    if (
      solution.suitableForHighWaterContent === false &&
      waterContent === "high"
    ) {
      setSeparationNeeded(true);
      return;
    }
  };

  const renderImage = () => {
    if (eqaulisingTankNeeded && separationNeeded) {
      return "Flow Diagrams/Balanced/ConstructedWetland_Balanced_Separated";
    }

    if (eqaulisingTankNeeded) {
      return "Flow Diagrams/Balanced/ConstructedWetland_Balanced";
    }

    switch (selectedSolution.technology) {
      case "Waste Stabillsation Ponds":
        return separationNeeded
          ? "Flow Diagrams/Separated/WasteStabalisationPonds_separated.jpg"
          : "Flow Diagrams/Normal/WasteStabalisationPonds.jpg";
      case "Anaerobic Baffled Reactors":
        return separationNeeded
          ? "Flow Diagrams/Separated/AnaerobicBaffledReactors_separated.jpg"
          : "Flow Diagrams/Normal/AnaerobicBaffledReactors.jpg";
      case "Anaerobic Lagoon":
        return separationNeeded
          ? "Flow Diagrams/Separated/AnaerobicLagoon_Separated.jpg"
          : "Flow Diagrams/Normal/AnaerobicLagoon.jpg";
      case "Aeration":
        return separationNeeded
          ? "Flow Diagrams/Separated/Aeration_Separated.jpg"
          : "Flow Diagrams/Normal/Aeration.jpg";
      case "Upflow Filter":
        return separationNeeded
          ? "Flow Diagrams/Separated/UpflowFilter_Separated.jpg"
          : "Flow Diagrams/Normal/UpflowFilter.jpg";
      case "Biogas reactor":
        return separationNeeded
          ? "Flow Diagrams/Separated/BiogasReactor_Separated.jpg"
          : "Flow Diagrams/Normal/BiogasReactor.jpg";
      case "Lagoon Lime treatment":
        return separationNeeded
          ? "Flow Diagrams/Separated/LagoonLimeTreatment_Separated.jpg"
          : "Flow Diagrams/Normal/LagoonLimeTreatment.jpg";
      case "Lime tank":
        return separationNeeded
          ? "Flow Diagrams/Separated/LimeTank_Separated.jpg"
          : "Flow Diagrams/Normal/LimeTanks.jpg";
      case "Lime in-barrel treatment":
        return separationNeeded
          ? "Flow Diagrams/Separated/Limein-BarrelTreatment_Separated.jpg"
          : "Flow Diagrams/Normal/Limein-BarrelTreatment.jpg";
      case "Constructed Wetland":
        return separationNeeded
          ? "Flow Diagrams/Separated/ConstructedWetland_Separated.jpg"
          : "Flow Diagrams/Normal/ConstructedWetland.jpg";
      default:
        return "";
    }
  };

  const getTechnologyPhoto = () => {
    switch (selectedSolution.technology) {
      case "Waste Stabillsation Ponds":
        return "Technology Photos/Waste Stabilisation Ponds Tech Photo.jpg";
      case "Anaerobic Baffled Reactors":
        return "/Technology Photos/Anaerobic Baffled Reactors Tech Photo.jpg";
      case "Anerobic Lagoon":
        return "Technology Photos/Anaerobic Lagoon Tech Photo.jpg";
      case "Aeration":
        return "Technology Photos/Aeration Tech Photo.jpg";
      case "Upflow Filter":
        return "Technology Photos/Upflow Filter Tech Photo.jpg";
      case "Biogas reactor":
        return "Technology Photos/Biogas Reactor Tech Photo";
      case "Lagoon Lime treatment.jpg":
        return "Technology Photos/Lagoon Lime Treatment,jpg";
      case "Lime Tanks":
        return "Technology Photos/Lime Tank Tech Photo.jpg";
      case "Lime in-barrel treatment":
        return "Technology Photos/Lime In-Barrel Treatment Tech Photo.jpg";
      case "Constructed Wetland":
        return "Technology Photos/Constructed Wetland Tech Photo.jpg";
      default:
        return "";
    }
  };

  const renderCapex = (solution) => {
    const capexLower = parseFloat(
      solution.capexLowerBoundary * averageQuantityLower
    );
    const capexUpper = parseFloat(
      solution.capexUpperBoundary * averageQuantityUpper
    );
    return `${capexLower.toLocaleString("en-US")} - ${capexUpper.toLocaleString(
      "en-US"
    )}`;
  };

  return (
    <>
      {finalSolutions && (
        <Box width={"95%"} height={"80%"}>
          <Box
            width={"40%"}
            height={"7%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={"#DAE1EF"}
            sx={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
          >
            <Typography color={"#233A5C"} variant="h5" fontWeight={"bold"}>
              Suggested Solution
            </Typography>
          </Box>
          <Box border={1}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Treatment Methods" />
              <Tab
                label="Technology Page"
                disabled={selectedSolution === undefined}
              />
              <Tab label="Rough Plant Schematic" />
            </Tabs>
            <>
              {value === 0 && (
                <>
                  <Box
                    bgcolor={"#EAF8E5"}
                    width={"100%"}
                    paddingBottom={"20px"}
                  >
                    <Box padding={"20px"}>
                      <Typography textAlign={"left"} fontWeight={"500"}>
                        Recommended
                      </Typography>
                    </Box>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <TableContainer component={Paper} sx={{ width: "95%" }}>
                        <Table style={{ width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Solution</TableCell>
                              <TableCell align="center">
                                Land Footprint Estimate (m2)
                              </TableCell>
                              <TableCell align="center">
                                CAPEX Estimate ($)
                              </TableCell>
                              <TableCell align="center">
                                OPEX Estimate ($ Per Day)
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {finalSolutions.map((solution) => (
                              <TableRow
                                key={solution.technology}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {solution.technology}
                                </TableCell>
                                <TableCell align="center">
                                  {solution.landFootPrintLowerBoundary *
                                    parseInt(
                                      averageQuantityOfSludge.split("-")[0]
                                    ) +
                                    "-" +
                                    solution.landFootPrintUpperBoundary *
                                      parseInt(
                                        averageQuantityOfSludge.split("-")[1]
                                      )}
                                </TableCell>
                                <TableCell align="center">
                                  {renderCapex(solution)}
                                </TableCell>

                                <TableCell align="center">
                                  {parseFloat(
                                    solution.opexLowerBoundary *
                                      averageQuantityLower
                                  ).toFixed(2)}
                                  -
                                  {parseFloat(
                                    solution.opexUpperBoundary *
                                      averageQuantityUpper
                                  ).toFixed(2)}
                                </TableCell>

                                <TableCell>
                                  <SelectSolution
                                    isSelected={solution === selectedSolution}
                                    onClick={() => onSelectClick(solution)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                  <Box
                    bgcolor={"#F8E5E5"}
                    width={"100%"}
                    paddingBottom={"20px"}
                  >
                    <Box padding={"20px"}>
                      <Typography textAlign={"left"}>
                        Not Recommended
                      </Typography>
                    </Box>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <TableContainer component={Paper} sx={{ width: "95%" }}>
                        <Table style={{ width: "100%", height: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Solution</TableCell>
                              <TableCell align="center">Reason</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {eliminatedSolutions.map((solution) => (
                              <TableRow
                                align="center"
                                key={solution.technology + solution.reason}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {solution.technology}
                                </TableCell>
                                <TableCell align="center">
                                  {solution.reason}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </>
              )}
              {value === 1 && (
                <TechnologyPage
                  solution={selectedSolution}
                  availableLand={availableLandTransition}
                  averageQuantity={averageQuantityOfSludge}
                />
              )}
              {value === 2 && (
                <Box>
                  {selectedSolution && (
                    <>
                      <img
                        width={"100%"}
                        src={renderImage()}
                        alt="flow diagram"
                      />
                      <TransitionPDFFile
                        projectName={projectName}
                        projectDescription={projectDescription}
                        location={location}
                        date={date}
                        finalSolutions={finalSolutions}
                        selectedSolution={selectedSolution}
                        eliminatedSolutions={eliminatedSolutions}
                        averageQuantityOfSludge={averageQuantityOfSludge}
                        natureOfSludgeFlow={natureOfSludgeFlow}
                        waterContent={waterContent}
                        availableLandTransition={availableLandTransition}
                        powerAvailable={powerAvailable}
                        skilledLabour={skilledLabour}
                        flowDiagram={renderImage()}
                        technologyPhoto={getTechnologyPhoto()}
                        capexLower={
                          selectedSolution.capexLowerBoundary *
                          averageQuantityLower
                        }
                        capexUpper={
                          selectedSolution.capexUpperBoundary *
                          averageQuantityUpper
                        }
                        opexLower={
                          selectedSolution.opexLowerBoundary *
                          averageQuantityLower
                        }
                        opexUpper={
                          selectedSolution.opexUpperBoundary *
                          averageQuantityUpper
                        }
                      />
                    </>
                  )}
                </Box>
              )}
            </>
          </Box>
        </Box>
      )}
    </>
  );
};
