import { Box, Typography } from "@mui/material";

export const TechnologyPage = ({
  solution,
  availableLand,
  averageQuantity,
}) => {
  const averageQuantityLower = parseInt(averageQuantity.split("-")[0]);
  const averageQuantityUpper = parseInt(averageQuantity.split("-")[1]);

  const renderConsiderations = () => {
    return solution.considerations.map((consideration, i) => {
      if (typeof consideration === "object") {
        return (
          <li key={consideration + i}>
            {consideration.text} <a href={consideration.link}>here.</a>
          </li>
        );
      } else {
        return <li key={consideration}>{consideration}</li>;
      }
    });
  };

  const renderCaseStudies = () => {
    return solution.technologyCaseStudies.map((caseStudy) => {
      if (caseStudy.name === "") {
        return (
          <li key={caseStudy.name}>
            <a href={caseStudy.link}>{caseStudy.link}</a>
          </li>
        );
      } else {
        return (
          <li key={caseStudy.name}>
            <a href={caseStudy.link}>{caseStudy.name}</a>
          </li>
        );
      }
    });
  };

  const renderTreatmentMechanisms = () => {
    return solution.treatmentMechanisms.map((mechanism, i) => {
      return <li key={mechanism + i}>{mechanism}</li>;
    });
  };

  const renderLandRequired = () => {
    if (
      availableLand.split("-")[0] < solution.landFootPrintLowerBoundary ||
      availableLand.split("-")[1] < solution.landFootPrintUpperBoundary
    ) {
      return (
        <Typography variant="h5">
          Your available space is too small. The estimated land footprint for
          your required sludge is{" "}
          {solution.landFootPrintLowerBoundary *
            parseInt(averageQuantity.split("-")[0])}{" "}
          -{" "}
          {solution.landFootPrintUpperBoundary *
            parseInt(averageQuantity.split("-")[1])}{" "}
          m2.
        </Typography>
      );
    } else {
      return (
        <Typography variant="h6">
          {solution.landFootPrintLowerBoundary *
            parseInt(averageQuantity.split("-")[0]) +
            " m2 - " +
            solution.landFootPrintUpperBoundary *
              parseInt(averageQuantity.split("-")[1]) +
            " m2 estimated land required."}
        </Typography>
      );
    }
  };

  const getTechnologyPhoto = () => {
    switch (solution.technology) {
      case "Waste Stabillsation Ponds":
        return "Technology Photos/Waste Stabilisation Ponds Tech Photo.jpg";
      case "Anaerobic Baffled Reactors":
        return "/Technology Photos/Anaerobic Baffled Reactors Tech Photo.jpg";
      case "Anaerobic Lagoon":
        return "Technology Photos/Anaerobic Lagoon Tech Photo.jpg";
      case "Aeration":
        return "Technology Photos/Aeration Tech Photo.jpg";
      case "Upflow Filter":
        return "Technology Photos/Upflow Filter Tech Photo.jpg";
      case "Biogas reactor":
        return "Technology Photos/Biogas Reactor Tech Photo";
      case "Lagoon Lime treatment.jpg":
        return "Technology Photos/Lagoon Lime Treatment,jpg";
      case "Lime tank":
        return "Technology Photos/Lime Tank Tech Photo.jpg";
      case "Lime in-barrel treatment":
        return "Technology Photos/Lime In-Barrel Treatment Tech Photo.jpg";
      case "Constructed Wetland":
        return "Technology Photos/Constructed Wetland Tech Photo.jpg";
      default:
        return "";
    }
  };

  const renderCapex = () => {
    const capexLower = parseFloat(
      solution.capexLowerBoundary * averageQuantityLower
    );
    const capexUpper = parseFloat(
      solution.capexUpperBoundary * averageQuantityUpper
    );
    return `${capexLower.toLocaleString("en-US")} - ${capexUpper.toLocaleString(
      "en-US"
    )}`;
  };

  const renderTechnologySheets = () => {
    if (solution.technologySheets.length === 1) {
      return (
        <li>
          Technology Sheet: <a href={solution.technologySheets}>Here</a>
        </li>
      );
    } else {
      return (
        <>
          <Box display={"flex"} flexDirection={"column"}>
            {solution.technologySheets.map((technologySheet, i) => {
              return (
                <li>
                  <a href={technologySheet}>Link {i + 1}</a>
                </li>
              );
            })}
          </Box>
        </>
      );
    }
  };

  return (
    <Box height={"100%"} width={"100%"} display={"flex"} alignItems={"center"}>
      <Box
        width={"100%"}
        height={"82%"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box height={"100%"} padding={"20px"} marginRight={"40px"}>
          <Box
            display={"flex"}
            marginBottom={"20px"}
            justifyContent={"space-between"}
          >
            <Typography
              textTransform={"uppercase"}
              color={"#233A5C"}
              variant="h4"
              fontWeight={"bold"}
            >
              {solution.technology}
            </Typography>
          </Box>
          <Box display={"flex"} maxHeight={"40%"}>
            <Box
              height={"100%"}
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignContent={"center"}
              textAlign={"left"}
            >
              <Typography variant="h5">
                Estimated CAPEX is ${renderCapex()}
              </Typography>
              <Typography variant="h5" sx={{ paddingBottom: "20px" }}>
                Estimated OPEX is $
                {solution.opexLowerBoundary * averageQuantityLower} - $
                {solution.opexUpperBoundary * averageQuantityUpper} per day
              </Typography>
              {renderLandRequired()}
            </Box>
            <Box
              height={"100%"}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                width={"85%"}
                src={getTechnologyPhoto()}
                alt="flow diagram"
              />
            </Box>
          </Box>
          <Box height={"50%"} width={"100%"} overflow={"auto"}>
            <Box textAlign={"start"} marginBottom={"20px"}>
              <Typography fontWeight={"bold"}>
                Source of Data for Estimates
              </Typography>
              <Typography>{solution.sourceOfInfoText}</Typography>
              <ul>
                {solution.sourceOfInfo.map((source, i) => {
                  return (
                    <li key={source.name + i}>
                      <a href={source.link}>{source.name}</a>
                    </li>
                  );
                })}
              </ul>
            </Box>
            <Box textAlign={"start"}>
              <Typography fontWeight={"bold"}>Technology Sheets:</Typography>
              <ul>{renderTechnologySheets()}</ul>
              <Typography fontWeight={"bold"}>Considerations: </Typography>
              <ul>{renderConsiderations()}</ul>
              <Typography fontWeight={"bold"}>Treatment Mechanisms</Typography>
              <ul>{renderTreatmentMechanisms()}</ul>
              <Typography fontWeight={"bold"}>Case Studies: </Typography>
              <ul>{renderCaseStudies()}</ul>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
