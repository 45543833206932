import { Box, Tab, Tabs, Typography } from "@mui/material";
import { AcutePDFFile } from "../../components/AcutePDFFile";
import { useState } from "react";

export const AcuteSolution = ({
  projectName,
  location,
  projectDescription,
  date,
  finalSolution,
  averageQuantity,
  availableLand,
  collectedInBarrels,
}) => {
  const averageQuantityLower = parseInt(averageQuantity.split("-")[0]);
  const averageQuantityUpper = parseInt(averageQuantity.split("-")[1]);
  const [value, setValue] = useState(0);

  const getImage = () => {
    switch (finalSolution.technology) {
      case "Lagoon Lime treatment":
        return "Flow Diagrams/Separated/LagoonLimeTreatment_Separated.jpg";

      case "Lime in-barrel treatment":
        return "Flow Diagrams/Separated/LimeIn-BarrelTreatment_Separated.jpg";

      default:
        return "Flow Diagrams/Separated/LimeTank_Separated.jpg";
    }
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const renderConsiderations = () => {
    return finalSolution.considerations.map((consideration, i) => {
      if (typeof consideration === "object") {
        return (
          <li key={consideration + i}>
            {consideration.text} <a href={consideration.link}>here.</a>
          </li>
        );
      } else {
        return <li key={consideration}>{consideration}</li>;
      }
    });
  };

  const renderTreatmentMechanisms = () => {
    return finalSolution.treatmentMechanisms.map((mechanism, i) => {
      return <li key={mechanism + i}>{mechanism}</li>;
    });
  };

  const renderCaseStudies = () => {
    return finalSolution.technologyCaseStudies.map((caseStudy) => {
      if (caseStudy.name === "") {
        return (
          <li key={caseStudy.name}>
            <a href={caseStudy.link}>{caseStudy.link}</a>
          </li>
        );
      } else {
        return (
          <li key={caseStudy.name}>
            <a href={caseStudy.link}>{caseStudy.name}</a>
          </li>
        );
      }
    });
  };

  const renderLandRequired = () => {
    if (
      availableLand.split("-")[0] <
        finalSolution.landFootPrintLowerBoundary *
          parseInt(averageQuantity.split("-")[0]) ||
      availableLand.split("-")[1] <
        finalSolution.landFootPrintUpperBoundary *
          parseInt(averageQuantity.split("-")[1])
    ) {
      return (
        <Typography variant="h5">
          Your available space is too small. The estimated land footprint for
          your required sludge is{" "}
          {finalSolution.landFootPrintLowerBoundary *
            parseInt(averageQuantity.split("-")[0])}{" "}
          -{" "}
          {finalSolution.landFootPrintUpperBoundary *
            parseInt(averageQuantity.split("-")[1])}{" "}
          m2.
        </Typography>
      );
    } else {
      return (
        <Typography variant="h6">
          {finalSolution.landFootPrintLowerBoundary *
            parseInt(averageQuantity.split("-")[0]) +
            " m2 - " +
            finalSolution.landFootPrintUpperBoundary *
              parseInt(averageQuantity.split("-")[1]) +
            " m2 estimated land required."}
        </Typography>
      );
    }
  };

  const getTechnologyPhoto = () => {
    switch (finalSolution.technology) {
      case "Lagoon Lime treatment":
        return "Technology Photos/Lagoon Lime Treatment Tech Photo.jpg";
      case "Lime tank":
        return "Technology Photos/Lime Tank Tech Photo.jpg";
      case "Lime in-barrel treatment":
        return "Technology Photos/Lime In-Barrel Treatment Tech Photo.jpg";
      default:
        return "";
    }
  };

  const renderCapex = () => {
    const capexLower = parseFloat(
      finalSolution.capexLowerBoundary * averageQuantityLower
    );
    const capexUpper = parseFloat(
      finalSolution.capexUpperBoundary * averageQuantityUpper
    );
    return `${capexLower.toLocaleString("en-US")} - ${capexUpper.toLocaleString(
      "en-US"
    )}`;
  };

  const renderImage = () => {
    switch (finalSolution.technology) {
      case "Lagoon Lime treatment":
        return "Flow Diagrams/separated/LagoonLimeTreatment_Separated.jpg";
      case "Lime tank":
        return "Flow Diagrams/separated/LimeTank_Separated.jpg";
      case "Lime in-barrel treatment":
        return "Flow Diagrams/separated/LimeIn-BarrelTreatment_Separated.jpg";
      default:
        return "";
    }
  };

  const renderSolution = () => (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Treatment Method" />
        <Tab label="Typical flow process diagram" />
      </Tabs>
      <>
        {value === 0 && (
          <>
            <Box
              display={"flex"}
              marginBottom={"20px"}
              justifyContent={"space-between"}
            >
              <Typography
                textTransform={"uppercase"}
                color={"#233A5C"}
                variant="h4"
                fontWeight={"bold"}
              >
                {finalSolution.technology}
              </Typography>
              <AcutePDFFile
                capexLower={
                  finalSolution.capexLowerBoundary * averageQuantityLower
                }
                capexUpper={
                  finalSolution.capexUpperBoundary * averageQuantityUpper
                }
                opexLower={
                  finalSolution.opexLowerBoundary * averageQuantityLower
                }
                opexUpper={
                  finalSolution.opexUpperBoundary * averageQuantityUpper
                }
                flowDiagram={getImage()}
                technologyPhoto={getTechnologyPhoto()}
                finalSolution={finalSolution}
                projectName={projectName}
                location={location}
                projectDescription={projectDescription}
                date={date}
                quantityOfSludge={averageQuantity}
                landFootprint={availableLand}
                collectedInBarrles={collectedInBarrels}
              />
            </Box>
            <Box display={"flex"} maxHeight={"50%"}>
              <Box
                height={"100%"}
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignContent={"center"}
                textAlign={"left"}
              >
                <Typography variant="h5">
                  Estimated CAPEX is ${renderCapex()}
                </Typography>
                <Typography variant="h5">
                  Estimated OPEX is $
                  {finalSolution.opexLowerBoundary * averageQuantityLower} - $
                  {finalSolution.opexUpperBoundary * averageQuantityUpper} per
                  day
                </Typography>
                {renderLandRequired()}
              </Box>
              <Box
                height={"100%"}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  width={"85%"}
                  src={getTechnologyPhoto()}
                  alt="flow diagram"
                />
              </Box>
            </Box>
            <Box
              height={"100%"}
              width={"100%"}
              overflow={"auto"}
              marginTop={"5px"}
            >
              <Box textAlign={"start"} marginBottom={"20px"}>
                <Typography fontWeight={"bold"}>
                  Source of Data for Estimates
                </Typography>
                <Typography>{finalSolution.sourceOfInfoText}</Typography>
                <ul>
                  {finalSolution.sourceOfInfo.map((source, i) => {
                    return (
                      <li key={source.name + i}>
                        <a href={source.link}>{source.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </Box>
              <Box textAlign={"start"}>
                <Typography fontWeight={"bold"}>
                  Technology Sheet:{" "}
                  <a href={finalSolution.technologySheets}>Here</a>
                </Typography>
                <Typography fontWeight={"bold"}>Considerations: </Typography>
                <ul>{renderConsiderations()}</ul>
                <Typography fontWeight={"bold"}>
                  Treatment Mechanisms
                </Typography>
                <ul>{renderTreatmentMechanisms()}</ul>
                <Typography fontWeight={"bold"}>Case Studies: </Typography>
                <ul>{renderCaseStudies()}</ul>
              </Box>
            </Box>
          </>
        )}
        {value === 1 && (
          <>
            <img width={"100%"} src={renderImage()} alt="flow diagram" />
          </>
        )}
      </>
    </>
  );

  return (
    <>
      {finalSolution ? (
        <Box
          height={"94%"}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Box
            width={"40%"}
            maxHeight={"40px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={"#DAE1EF"}
            sx={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
          >
            <Typography color={"#233A5C"} variant="h5" fontWeight={"bold"}>
              Suggested Solution
            </Typography>
          </Box>
          <Box
            width={"95%"}
            height={"93%"}
            display={"flex"}
            flexDirection={"column"}
            border={1}
            padding={"15px"}
          >
            {renderSolution()}
          </Box>
        </Box>
      ) : null}
    </>
  );
};
