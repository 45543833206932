import { Box, Avatar, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from '../images/Logo.png';

export const WelcomePage = ({ enterDisabled, setEnterDisabled }) => {
  const navigate = useNavigate();

  const onChange = () => {
    setEnterDisabled(false);
  };

  return (
    <Box
      height={"45vh"}
      width={"40vw"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-around"}
      flexDirection={"column"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Avatar src={Logo} sx={{ width: 112, height: 112, marginBottom: 3 }} alt="logo" />
        <Typography variant="h5">Welcome to the</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          FSTP Decision Tool
        </Typography>
      </Box>

      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={onChange}
        />
      </Box>

      <Box width={"100%"}>
        <Button
          sx={{
            height: "120%",
            width: "40vh",
            bgcolor: "#283959",
            fontWeight: "bold",
          }}
          disabled={enterDisabled}
          onClick={() => navigate("/introduction")}
          variant="contained"
        >
          Enter
        </Button>
      </Box>
    </Box>
  );
};
