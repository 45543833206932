import { Button } from "@mui/material";
import jsPDF, { AcroFormCheckBox } from "jspdf";
import moment from "moment";
import React from "react";

export const AcutePDFFile = ({
  projectName,
  location,
  projectDescription,
  date,
  quantityOfSludge,
  landFootprint,
  collectedInBarrles,
  finalSolution,
  opexLower,
  opexUpper,
  capexLower,
  capexUpper,
  flowDiagram,
  technologyPhoto
}) => {
  const generatePDF = () => {
    // Need to add the text in that when no solution is suitabe - you need x
    // amount of land to do this
    const pdf = new jsPDF("landscape");

    // Page 1
    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(40);
    pdf.text("Acute Phase", 15, 20);

    pdf.setTextColor("#339237");
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(25);
    pdf.text("Solution Summary", 15, 33);

    pdf.setTextColor("black");
    pdf.setFontSize(17);
    pdf.text("FSTP Decision Tool", 230, 20);

    pdf.setTextColor(150);
    pdf.text("Entry Record", 247, 30);

    pdf.setFillColor("#DAE1EF");
    pdf.rect(10, 79, 275, 125, "f");

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.text("Project Information", 25, 100);

    pdf.setLineWidth(0.5);
    pdf.setDrawColor("#b8b8b8");
    pdf.line(80, 99, 150, 99);

    pdf.setTextColor("black");
    pdf.text("Project Name", 25, 115);
    pdf.setFont("helvetica", "normal");
    pdf.text(projectName, 25, 124);

    pdf.setFont("helvetica", "bold");
    pdf.text("Location", 102, 115);
    pdf.setFont("helvetica", "normal");
    pdf.text(location, 102, 124);

    pdf.setFont("helvetica", "bold");
    pdf.text("Description", 25, 140);
    pdf.setFont("helvetica", "normal");
    const maxWidth = 120;
    const splitText = pdf.splitTextToSize(projectDescription, maxWidth);
    pdf.text(25, 149, splitText);

    pdf.setFont("helvetica", "bold");
    pdf.text("Date", 25, 180);
    pdf.setFont("helvetica", "normal");
    pdf.text(moment(date).format("DD/MM/YYYY"), 25, 189);

    pdf.setTextColor("#233A5C");
    pdf.text("Context Inputs", 160, 100);
    pdf.setLineWidth(0.5);
    pdf.setDrawColor("#b8b8b8");
    pdf.line(205, 99, 266, 99);

    pdf.setTextColor("black");
    pdf.setFont("helvetica", "bold");
    pdf.text("Average quantity of sludge to be treated", 160, 115);
    pdf.setFont("helvetica", "normal");
    pdf.text(quantityOfSludge + " (m3/day)", 160, 124);

    pdf.setFont("helvetica", "bold");
    pdf.text("Available land footprint for a solution", 160, 140);
    pdf.setFont("helvetica", "normal");
    pdf.text(landFootprint + " (m2)", 160, 149);

    pdf.setFont("helvetica", "bold");
    pdf.text("Is the sludge collected in barrels?", 160, 165);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      collectedInBarrles.charAt(0).toUpperCase() + collectedInBarrles.slice(1),
      160,
      174
    );

    // Page 2
    pdf.addPage("landscape");

    pdf.setTextColor(100);
    pdf.setFontSize(15);
    pdf.text("Acute Phase", 15, 20);

    pdf.setTextColor("black");
    pdf.text("FSTP Decision Tool", 232, 20);

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.text("Suggested Solution", 15, 29);
    pdf.line(74, 28, 238, 28);
    pdf.setTextColor(100);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(15);
    pdf.text("Entry Record", 248, 29);

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    pdf.text(finalSolution.technology, 15, 48);

    pdf.setFillColor("#DAE1EF");
    pdf.rect(15, 52, 170, 40, "f");

    pdf.setFont("helvetica", "normal");
    pdf.setTextColor("black");
    pdf.setFontSize(15);
    pdf.text(
      "Estimated CAPEX is $" + capexLower + " to $" + capexUpper,
      20,
      62
    );

    pdf.text(
      "Estimated OPEX is $" + opexLower + " to $" + opexUpper + " per month.",
      20,
      73
    );

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    pdf.text("Flow Diagram", 15, 102);
    pdf.addImage(flowDiagram, "JPEG", 65, 107, 170, 100);

    pdf.addImage(technologyPhoto, "JPEG", 190, 43, 95, 49);
    pdf.setTextColor("#233A5C");
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "normal");
    pdf.text("Photo: Example of - " + finalSolution.technology, 190, 98);

    // Page 3
    pdf.addPage("landscape");
    pdf.setTextColor(100);
    pdf.setFontSize(15);
    pdf.text("Acute Phase", 15, 20);

    pdf.setTextColor("black");
    pdf.text("FSTP Decision Tool", 232, 20);

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.text("Suggested Solution", 15, 29);
    pdf.line(74, 28, 238, 28);
    pdf.setTextColor(100);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(15);
    pdf.text("Entry Record", 248, 29);

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.text("Key Considerations", 15, 40);

    const maxWidth2 = 160;
    pdf.setFontSize(9);
    pdf.setTextColor("black");
    pdf.setFont("helvetica", "normal");

    let yValue = 46;
    let links = [];
    pdf.setFontSize(8)
    finalSolution.pdfConsiderations.forEach((consideration) => {
      if (typeof consideration === "string") {
        pdf.setTextColor("black");
        const splitString = pdf.splitTextToSize(consideration, maxWidth2);
        pdf.text(15, yValue, splitString);
        yValue = yValue + 9.5;
      } else {
        const splitString = pdf.splitTextToSize(consideration.text, maxWidth2);
        pdf.setTextColor("black");
        pdf.text(15, yValue, splitString);
        links.push(consideration.link)
        yValue = yValue + 20;
      }
    });
    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(15, yValue, "Links");
    yValue = yValue + 5;
    pdf.setFontSize(9);
    pdf.setTextColor("blue");
    pdf.setFont("helvetica", "normal");
    links.forEach((link, i) => {
      pdf.textWithLink("Link " + (i + 1), 15, yValue, {
        url: link
      });
      yValue = yValue + 5;
    })

    // track the yValue as the source of information height can vary depending on number
    // of references
    let soiYValue = 55;
    const maxWidth3 = 100;
    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    pdf.text("Sources of Information for Estimates", 190, 40);
    pdf.setFontSize(9);
    pdf.setTextColor("black");
    pdf.setFont("helvetica", "normal");
    finalSolution.sourceOfInfo.forEach((ref) => {
      const splitString = pdf.splitTextToSize(ref.name, maxWidth3);
      pdf.text(190, soiYValue, splitString);
      pdf.setTextColor("#0000EE");
      pdf.textWithLink("Link", 190, soiYValue + 10, {
        url: ref.link,
      });
      soiYValue = soiYValue + 20;
    });

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(11);
    soiYValue = soiYValue + 10;
    pdf.text("Link to Technology Sheet & Further Guidance", 190, soiYValue);

    soiYValue = soiYValue + 10;
    pdf.setTextColor(100);
    pdf.setFont("helvetica", "normal");
    pdf.text("Technology Sheet: ", 190, soiYValue);

    const maxWidth4 = 80;
    soiYValue = soiYValue + 7;
    pdf.setTextColor("#0000EE");
    finalSolution.technologySheets.forEach((technologySheet) => {
      const splitString = pdf.splitTextToSize(technologySheet, maxWidth4);
      splitString.forEach((line) => {
        pdf.textWithLink(line, 190, soiYValue, {
          url: technologySheet,
        });
        soiYValue = soiYValue + 4;
      });
      soiYValue = soiYValue + 9;
    });
    
    soiYValue = soiYValue + 10;
    pdf.setTextColor(100);
    pdf.text("Case Study: ", 190, soiYValue);
    pdf.setTextColor("#0000EE");
    soiYValue = soiYValue + 8;
    finalSolution.technologyCaseStudies.forEach((caseStudy) => {
      const splitString = pdf.splitTextToSize(caseStudy.link, maxWidth4);
      splitString.forEach((line) => {
        pdf.textWithLink(line, 190, soiYValue, {
          url: caseStudy.link,
        });
        soiYValue = soiYValue + 4;
      });
      soiYValue = soiYValue + 5;
    });


    // Page 4
    pdf.addPage("landscape");
    pdf.setTextColor(100);
    pdf.setFontSize(15);
    pdf.text("Acute Phase", 15, 20);

    pdf.setTextColor("black");
    pdf.text("FSTP Decision Tool", 232, 20);

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.text("Suggested Solution", 15, 29);
    pdf.line(74, 28, 238, 28);
    pdf.setTextColor(100);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(15);
    pdf.text("Entry Record", 248, 29);

    const maxWidth5 = 230;

    pdf.setTextColor("#233A5C");
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(15);
    pdf.text("Checklist of Next Steps", 15, 45);

    pdf.setTextColor("black");
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(15);

    const splitString1 = pdf.splitTextToSize(
      "Design expertise - This tool is a guide for making initial decision on FSTPs. The appropriate local expertise should be used to confirm and design the selected solution",
      maxWidth5
    );
    pdf.text(splitString1, 38, 65);
    var checkBox = new AcroFormCheckBox();
    checkBox.fieldName = "CheckBox1";
    checkBox.Rect = [15, 56, 20, 20];
    pdf.addField(checkBox);

    const splitString2 = pdf.splitTextToSize(
      "Check effluent standards - local and international standards need to be considered in the design. Use more detailed tool (e.g. IFRC TOOL LINK) and available evidence (e.g. CxB FE Dashboard LINK https://public.tableau.com/app/profile/tanvir.ahmed/viz/FSTPEffluentQualityMonitoringDashboard/Dashboard1?publish=yes) to check removal mechanisms and expected final effluent quality against the required standard.",
      maxWidth5
    );
    pdf.text(splitString2, 38, 85);
    var checkBox2 = new AcroFormCheckBox();
    checkBox2.fieldName = "CheckBox2";
    checkBox2.Rect = [15, 85, 20, 20];
    pdf.addField(checkBox2);

    const splitString3 = pdf.splitTextToSize(
      "Cost - Once the FSTP selection is refined/confirmed, cost information (CAPEX/OPEX) should be checked for the local context. Cost of fuel and chemicals can have a significant influence on ongoing operational costs. Costs need to be estimated for the whole sludge chain.",
      maxWidth5
    );
    pdf.text(splitString3, 38, 125);
    var checkBox3 = new AcroFormCheckBox();
    checkBox3.fieldName = "CheckBox3";
    checkBox3.Rect = [15, 118, 20, 20];
    pdf.addField(checkBox3);

    const splitString4 = pdf.splitTextToSize(
      "Funding- Initial FSTP review (provided by this tool) and more detailed costing could be used as evidence to seek donor funding for your FSM.",
      maxWidth5
    );
    pdf.text(splitString4, 38, 153);
    var checkBox4 = new AcroFormCheckBox();
    checkBox4.fieldName = "CheckBox4";
    checkBox4.Rect = [15, 144, 20, 20];
    pdf.addField(checkBox4);

    pdf.save(projectName + ".pdf");
  };

  return <Button variant="outlined" onClick={generatePDF}>Export PDF</Button>;
};
