import { Box, Typography, Button } from "@mui/material";
import { TopMenu } from "../components/TopMenu";
import diagram1 from "../images/diagram1.svg";
import { useNavigate } from "react-router";

export const IntroductionPage = () => {
  const navigate = useNavigate();

  const downloadPDF = () => {
    const pdfUrl = process.env.PUBLIC_URL + "/FSTP Decision Tool Guidance.pdf";
    const anchorElement = document.createElement("a");
    anchorElement.href = pdfUrl;
    anchorElement.download = "FSTP Decision Tool Guidance.pdf";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  };

  return (
    <>
      <TopMenu />
      <Box
        width={"80%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        overflow={"auto"}
        margin={"30px"}
      >
        <Box
          width={"100%"}
          height={"78%"}
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
        >
          <Box display={"flex"} justifyContent={"flex-start"} marginBottom={5}>
            <Typography variant="h2" fontWeight={"500"}>
              Tool Introduction
            </Typography>
          </Box>
          <Box width={"80%"} marginBottom={3}>
            <Typography variant="body1" fontSize={20} align="left">
              This is a decision guidance tool for Faecal Sludge Treatment
              Plants (FSTPs) in humanitarian responses. THe aim of the tool is
              to support Water, Sanitation and Hydience (WASH)
              managers/practitoners to make decisions on which type of FSTP is
              most appropriate for their site circumstances and requirements.
              The tool is to guide initial decision making and the appropriate
              local expertise should be brought in to confirm and design the
              selected solution.
            </Typography>
          </Box>
          <Box width={"80%"} marginBottom={3}>
            <Typography variant="body1" fontSize={20} align="left">
              A full chain of Faecal Sludge Management (FSM) is required to
              properly manage faecal sludge in emergencies. The tool focuses on
              the 'treatment' element of the chain. Further guidance and tools
              on the planning and implementation of other elements in the
              sanitation chain is avaliable on SaniHub at{" "}
              <a href="https://spherestandards.org/wp-content/uploads/2018/06/Sphere_Handbook_2011_English.pdf">
                LINK
              </a>{" "}
              and from WASH Sector.
            </Typography>
          </Box>
          <Box display={"flex"} width={"80%"} marginBottom={3}>
            <Box>
              <img src={diagram1} alt="sanitation diagram" />
              <Typography color={"green"} align="left">
                Sanitation/FSM Chain
              </Typography>
            </Box>
            <Typography
              align="left"
              paddingLeft={"20px"}
              paddingRight={"20px"}
              variant="body1"
              fontSize={18}
            >
              An FSTP is made up of several stages of treatment with each stage
              had a variety of options for technology type. This decision tool
              focuses on recommending the most appropriate whole FSTP option,
              rather than standalone technologies.
            </Typography>
          </Box>
          <Box width={"80%"}>
            <Typography
              variant="body1"
              fontSize={20}
              align="left"
              marginBottom={3}
            >
              A limited number of treatment types are included based on the
              available/reviewed/practical evidence, at the time of publishing
              the decision tool (2023). A majority of the information is based
              on field data collected in Cox Bazaar Bangladesh between 2018 and
              2022.
            </Typography>
          </Box>
          <Box width={"80%"}>
            <Typography variant="body1" fontSize={20} align="left">
              FSTP recommendations are split into the ‘Acute phase’ e.g., first
              6 months to 1 year of emergency and the ‘transition phase’ e.g. 1
              year onwards. Further guidance on identifying the phase of
              emergency response can be found in{" "}
              <a href="https://www.emersan-compendium.org/en/faq/13-7-what-are-the-categories-used-to-distinguish-between-different-emergency-phases">
                LINK
              </a>
              .
            </Typography>
          </Box>
        </Box>
        <Box
          height={"20%"}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          borderTop={1}
        >
          <Typography color={"#B60007"} fontWeight={"bold"} align="left">
            Disclaimer:
          </Typography>
          <Typography color={"#B60007"} align="left">
            Numbers provided for this tool are to aid in decision only and
            should not be use for design purposes. This tool creates an initial
            decision and a competent designer should be used to progress to
            design with appropriate local expertise to confirm and design the
            selected solution.
          </Typography>
        </Box>
        <Box width={"100%"} display={"flex"}>
          <Button
            sx={{
              backgroundColor: "#233A5C",
              fontSize: "1.1rem",
              padding: "8px 0px",
              marginRight: "40px",
            }}
            variant="contained"
            fullWidth
            onClick={() => navigate("/project-information")}
          >
            Get Started
          </Button>
          <Button
            sx={{
              borderColor: "#233A5C",
              color: "#233A5C",
              fontSize: "1.1rem",
              padding: "8px 0px",
              marginLeft: "40px",
            }}
            variant="outlined"
            fullWidth
            onClick={downloadPDF}
          >
            Tool Guidance
          </Button>
        </Box>
      </Box>
    </>
  );
};
