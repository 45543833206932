import { useState } from "react";
import {
  Box,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Alert,
} from "@mui/material";
import { TopMenu } from "../../components/TopMenu";
import Cookies from "js-cookie";
import { TransitionSolution } from "./TransitionSolution";
import db from "../../static/database.json";
import InfoIcon from "@mui/icons-material/Info";
import { InfoDialog } from "./InfoDialog";

export const TransitionSolutionsPage = ({
  projectName,
  location,
  projectDescription,
  date,
  natureOfSludgeFlow,
  setNatureOfSludgeFlow,
  waterContent,
  setWaterContent,
  averageQuantityOfSludge,
  setAverageQuantityOfSludge,
  availableLandTransition,
  setAvailableLandTransition,
  powerAvailable,
  setPowerAvailable,
  skilledLabour,
  setSkilledLabour,
}) => {
  const [finalTransitionSolutions, setFinalTransitionSolutions] = useState();
  const [eliminatedSolutions, setEliminatedSolutions] = useState();
  const [open, setOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const onNatureOfSludgeFlowChange = (e) => {
    setNatureOfSludgeFlow(e.target.value);
  };

  const onWaterContentChange = (e) => {
    setWaterContent(e.target.value);
  };

  const onAverageQuantityOfSludgeChange = (e, newValue) => {
    setAverageQuantityOfSludge(e.target.value);
  };

  const onAvaliableLandChange = (e) => {
    setAvailableLandTransition(e.target.value);
  };

  const onPowerAvailableChange = (e, newValue) => {
    setPowerAvailable(newValue);
  };

  const onSkilledLabourChange = (e, newValue) => {
    setSkilledLabour(newValue);
  };

  const onSubmit = () => {
    if (powerAvailable === null) {
      setAlertMessage("You have not selected if power is available");
      setAlertVisible(true);
      return;
    }
    if (skilledLabour === null) {
      setAlertMessage("You have not selected if skilled labour is available");
      setAlertVisible(true);
      return;
    }
    setAlertVisible(false);
    getSolution();
    saveToCookie();
  };

  const saveToCookie = () => {
    if (Cookies.get("Accept-Cookies") !== "true") return;

    let data = JSON.parse(Cookies.get("project-data"));
    data.natureOfSludgeFlow = natureOfSludgeFlow;
    data.waterContent = waterContent;
    data.averageQuantityOfSludge = averageQuantityOfSludge;
    data.availableLandTransition = availableLandTransition;
    data.powerAvailable = powerAvailable;
    data.skilledLabour = skilledLabour;
    Cookies.set("project-data", JSON.stringify(data));
  };

  const getSolution = () => {
    const averageQuantityLower = parseInt(
      averageQuantityOfSludge.split("-")[0]
    );

    const averageQuantityUpper = parseInt(
      averageQuantityOfSludge.split("-")[1]
    );

    const availableLandMidPoint = parseInt(
      (parseInt(availableLandTransition.split("-")[0]) +
        parseInt(availableLandTransition.split("-")[1])) /
        2
    );

    let potentialSolutionsLandRequired = [];
    let removedSolutions = [];
    let finalSolutions = [];

    db.forEach((technology) => {
      const techMidPoint =
        (technology.landFootPrintLowerBoundary * averageQuantityLower +
          technology.landFootPrintUpperBoundary * averageQuantityUpper) /
        2;

      if (techMidPoint > availableLandMidPoint) {
        removedSolutions.push({
          technology: technology.technology,
          reason: "Not enough land available",
        });
      } else {
        potentialSolutionsLandRequired.push(technology);
      }
    });

    potentialSolutionsLandRequired.forEach((solution) => {
      if (solution.powerRequired && powerAvailable === "no") {
        removedSolutions.push({
          technology: solution.technology,
          reason: "Power required for this solution",
        });
      } else if (solution.skilledLabourRequired && skilledLabour === "no") {
        removedSolutions.push({
          technology: solution.technology,
          reason: "Skilled labour required for this solution",
        });
      } else {
        finalSolutions.push(solution);
      }
    });

    finalSolutions.sort(
      (a, b) => Math.abs(a.maturityRating - 1) - Math.abs(b.maturityRating - 1)
    );

    // Take the first three elements after sorting
    const closestObjects = finalSolutions.slice(0, 3);

    // Add maturity rating lower than other solutions to the array
    const tempRemainingSolutions = finalSolutions.filter(
      (item) => !closestObjects.includes(item)
    );
    const lowMaturityRatingSolutions = tempRemainingSolutions.map(
      (solution) => {
        return {
          technology: solution.technology,
          reason:
            "Feasible solution but not recommended as less mature than other feasible solutions",
        };
      }
    );
    setFinalTransitionSolutions(closestObjects);
    setEliminatedSolutions(lowMaturityRatingSolutions.concat(removedSolutions));
  };

  const handleClickOpen = (message) => {
    setInfoMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InfoDialog open={open} handleClose={handleClose} message={infoMessage} />
      <TopMenu />
      <Box width={"100%"} height={"100%"} display={"flex"}>
        <Box
          display={"flex"}
          width={"60%"}
          flexDirection={"column"}
          margin={"30px"}
          overflow={"auto"}
        >
          <Typography variant="h2" fontWeight={500} align="left">
            Transition Phase
          </Typography>
          <Typography variant="h5" align="left" color={"#00000099"}>
            The ‘Transition Phase’ is post the immediate emergency and in this
            tool is taken to be after the first year of the emergency response.
          </Typography>
          <Box
            marginTop={"30px"}
            align="left"
            height={"85%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-around"}
          >
            <Typography variant="h5">Input Context Information</Typography>
            <Box
              height={"18%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography>What is the nature of the sludge flow?</Typography>
              <FormControl fullWidth>
                <InputLabel id="nature-of-sludge-flow-label">Select</InputLabel>
                <Select
                  labelId="nature-of-sludge-flow-label"
                  id="nature-of-sludge-flow"
                  value={natureOfSludgeFlow}
                  label="select"
                  onChange={onNatureOfSludgeFlowChange}
                >
                  <MenuItem value={"batch"}>Batch</MenuItem>
                  <MenuItem value={"continuous"}>Continuous</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              height={"18%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Box display={"flex"}>
                <Typography paddingRight={"10px"}>
                  What is the water content of the sludge to be treated?
                </Typography>
                <InfoIcon
                  onClick={() =>
                    handleClickOpen([
                      "HIGH Water Content means you have a very liquid sludge, assumed to be <97% water (and >3% solids).",
                      "MEDIUM Water Content assumed to be in the range of 90% water (and 10% solids).",
                      "LOW Water Content means you have a reasonably thick sludge, assumed to be >80% water (and <20% solids).",
                      "Non pumpable sludges e.g cake, are not covered in this tool.",
                    ])
                  }
                />
              </Box>

              <FormControl fullWidth>
                <InputLabel id="water-content-label">Select</InputLabel>
                <Select
                  labelId="water-content-label"
                  id="water-content"
                  value={waterContent}
                  label="select"
                  onChange={onWaterContentChange}
                >
                  <MenuItem value={"low"}>Low</MenuItem>
                  <MenuItem value={"medium"}>Medium</MenuItem>
                  <MenuItem value={"high"}>High</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              height={"18%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography>
                What is the average quantity of sludge to be treated? (m3/day)?
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="average-quantity-of-sludge-label">
                  Select
                </InputLabel>
                <Select
                  labelId="average-quantity-of-sludge-label"
                  id="water-content"
                  value={averageQuantityOfSludge}
                  label="select"
                  onChange={onAverageQuantityOfSludgeChange}
                >
                  <MenuItem value="0-2">0-2</MenuItem>
                  <MenuItem value="2-4">2-4</MenuItem>
                  <MenuItem value="4-6">4-6</MenuItem>
                  <MenuItem value="6-8">6-8</MenuItem>
                  <MenuItem value="9-10">9-10</MenuItem>
                  <MenuItem value="10-15">10-15</MenuItem>
                  <MenuItem value="15-20">15-20</MenuItem>
                  <MenuItem value="20-25">20-25</MenuItem>
                  <MenuItem value="25-50">25-50</MenuItem>
                  <MenuItem value="50-100">50-100</MenuItem>
                  <MenuItem value="100-200">100-200</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              height={"18%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography>
                What is the available land footprint for a solution (m2)?
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="avaliable-land-label">Select</InputLabel>
                <Select
                  labelId="avaliable-land-label"
                  id="avaliable-land"
                  value={availableLandTransition}
                  label="select"
                  onChange={onAvaliableLandChange}
                >
                  <MenuItem value="0-50">0 - 50</MenuItem>
                  <MenuItem value="51-100">51 - 100</MenuItem>
                  <MenuItem value="101-200">101 - 200</MenuItem>
                  <MenuItem value="201-300">201 - 300</MenuItem>
                  <MenuItem value="301-400">301 - 400</MenuItem>
                  <MenuItem value="401-500">401 - 500</MenuItem>
                  <MenuItem value="501-700">501 - 700</MenuItem>
                  <MenuItem value="701-1000">701 - 1000</MenuItem>
                  <MenuItem value="1001-1250">1001 - 1250</MenuItem>
                  <MenuItem value="1251-1500">1251 - 1500</MenuItem>
                  <MenuItem value="1501-2000">1501 - 2000</MenuItem>
                  <MenuItem value="3000-3000">3000+</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              height={"15%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Typography>Is continuous power available?</Typography>
              <ToggleButtonGroup
                color="primary"
                value={powerAvailable}
                exclusive
                onChange={onPowerAvailableChange}
                aria-label="Platform"
              >
                <ToggleButton sx={{ width: "150px" }} value="yes">
                  Yes
                </ToggleButton>
                <ToggleButton sx={{ width: "150px" }} value="no">
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box
              height={"15%"}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
            >
              <Box display={"flex"}>
                <Typography paddingRight={"10px"}>
                  Is skilled labour available for FSTP operation?
                </Typography>
                <InfoIcon
                  onClick={() =>
                    handleClickOpen([
                      "Skilled labour in this case means staff have relevant qualification or practical experience in operating a WwTW i.e. a reasonable level of technical understand of biological and chemical treatment processes.",
                    ])
                  }
                />
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={skilledLabour}
                exclusive
                onChange={onSkilledLabourChange}
                aria-label="Platform"
              >
                <ToggleButton sx={{ width: "150px" }} value="yes">
                  Yes
                </ToggleButton>
                <ToggleButton sx={{ width: "150px" }} value="no">
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Button
              variant="contained"
              sx={{
                marginTop: "10px",
                backgroundColor: "#233A5C",
                minHeight: "45px",
                maxWidth: "120px",
                fontWeight: "bold",
              }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Box>
          {alertVisible && (
            <Alert severity="error" onClose={() => setAlertVisible(false)}>
              {alertMessage}
            </Alert>
          )}
        </Box>
        <Box
          overflow={"auto"}
          height={"100%"}
          display={"flex"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <TransitionSolution
            projectName={projectName}
            projectDescription={projectDescription}
            location={location}
            date={date}
            finalSolutions={finalTransitionSolutions}
            eliminatedSolutions={eliminatedSolutions}
            averageQuantityOfSludge={averageQuantityOfSludge}
            natureOfSludgeFlow={natureOfSludgeFlow}
            waterContent={waterContent}
            availableLandTransition={availableLandTransition}
            powerAvailable={powerAvailable}
            skilledLabour={skilledLabour}
          />
        </Box>
      </Box>
    </>
  );
};
