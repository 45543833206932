import { Typography, Box, Button } from "@mui/material";
import { TopMenu } from "../components/TopMenu";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

export const PhaseSelectionPage = ({ setPhase }) => {
  const navigate = useNavigate();

  const onClick = (phaseType) => {
    savePhaseToCookie(phaseType);
    if (phaseType === "acute") {
      setPhase("acute");
      navigate("/acute-solutions-page");
    } else if (phaseType === "transition") {
      setPhase("transition");
      navigate("/transition-solutions-page");
    }
  };

  const savePhaseToCookie = (phase) => {
    if (Cookies.get("Accept-Cookies") !== "true") return;

    let data = JSON.parse(Cookies.get("project-data"));
    data.phase = phase;
    Cookies.set("project-data", JSON.stringify(data));
  };

  return (
    <>
      <TopMenu />
      <Box height={"100%"} width={"80%"} margin={"30px"}>
        <Typography
          variant="h2"
          fontWeight={500}
          align="left"
          marginLeft={"40px"}
        >
          Phase Selection
        </Typography>
        <Typography
          variant="h5"
          align="left"
          color={"#00000099"}
          marginLeft={"40px"}
        >
          What are you designing for?
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          height={"85%"}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            height={"65%"}
            margin={"40px"}
          >
            <Typography variant="h4" align="left">
              Acute Phase
            </Typography>
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-evenly"}
            >
              <Typography align="left" variant="h6">
                The ‘Acute Emergency Phase’ in this tool is taken to be the
                first 6 months to 1 year of emergency. This phase is often
                distinguished by population movement.
              </Typography>
              <Typography align="left" variant="h6">
                During this phase, humanitarian organisations begin to respond,
                focusing on providing critical services such as food, water,
                sanitation, primary healthcare, and shelter. The (FSM) priority
                during this phase is to quickly set up an FSTP to achieve
                pathogen kill to protect human health.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#233A5C",
                minHeight: "45px",
                maxWidth: "210px",
                fontWeight: "bold",
              }}
              onClick={() => onClick("acute")}
            >
              Select Acute Phase
            </Button>
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            height={"65%"}
            justifyContent={"space-evenly"}
            margin={"40px"}
          >
            <Typography variant="h4" align="left">
              Transition Phase
            </Typography>
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-evenly"}
            >
              <Typography variant="h6" align="left">
                The ‘Transition Phase’ is post the immediate emergency and in
                this tool is taken to be after the first year of the emergency
                response.
              </Typography>
              <Typography variant="h6" align="left">
                During this phase humanitarian organisations turn their focus to
                providing more routine services, developing local capacity and
                planning long-term options. The (FSM) priority during this phase
                is to develop a (environmentally and economically) sustainable
                FSTP that will operate adequately (to meet required human and
                environmental protection standards) in the longer term.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#233A5C",
                minHeight: "45px",
                maxWidth: "300px",
                fontWeight: "bold",
              }}
              onClick={() => onClick("transition")}
            >
              Select Transition Phase
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
