import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Fragment } from "react";

export const InfoDialog = ({ message, open, handleClose }) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Information"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message &&
              message.map((sentence, i) => (
                <Fragment key={i}>
                  {i > 0 && <br />}
                  {sentence}
                </Fragment>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
