import { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import cookiesImg from "../images/cookiesImg.svg";
import Cookies from "js-cookie";

export const CookeModal = () => {
  const [open, setOpen] = useState(
    Cookies.get("Accept-Cookies") === "true" ? false : true
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    outline: "none",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const onAccept = () => {
    Cookies.set("Accept-Cookies", true, { expires: 7 });
    setOpen(false);
  };

  const onSkip = () => {
    Cookies.set("Accept-Cookies", false, { expires: 7 });
    setOpen(false);
  };

  return (
    <Modal open={open} className="custom-modal-content">
      <Box sx={style}>
        <img src={cookiesImg} alt="Cookies" />
        <Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            marginTop={"20px"}
            fontWeight={"bold"}
            align="center"
          >
            WE USE COOKIES!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This site uses cookies to store your input. Please accept these
            cookies in order for data to be saved for later use.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Not accepting cookies will result in loss of data upon exiting the
            site.
          </Typography>
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          marginTop={"25px"}
        >
          <Button
            onClick={onAccept}
            sx={{
              backgroundColor: "#233A5C",
              fontWeight: "bold",
              minHeight: "55px",
              width: "200px",
            }}
            variant="contained"
          >
            Accept Cookies
          </Button>
          <Button
            onClick={onSkip}
            sx={{
              borderColor: "#233A5C",
              color: "#233A5C",
              minHeight: "55px",
              width: "175px",
            }}
            variant="outlined"
          >
            Skip
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
